import React, { useEffect, useState } from 'react'
import './CourseContent.scss'
import Vector from "./../../../../assets/icons/VectorBottom.svg"
import { ReactComponent as Circle } from './../../../../assets/svg/Circle.svg';
import { ReactComponent as FilmIcon } from './../../../../assets/icons/Film.svg'
import { ReactComponent as IconDow } from './../../../../assets/icons/Download.svg';
import axios from 'axios';
import { Sheet } from 'react-modal-sheet';
import { Viewer, Worker,OpenFile } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
const CourseContent = ({chapterCount,videoCount,chapterGetDtos}) => {
    const [activeChapter, setActiveChapter] = useState(null)
    const [previewVideo,setPreviewVideo]= useState(null)
    const [isOpen, setOpen] = useState(false);
    const [files,setFiles]=useState(null);

    const handleItemClick = (index) => {
        if (activeChapter === index) {
            setActiveChapter(null);
        } else {
            setActiveChapter(index);
        }
    };
    function downloadImage(imageUrl) {
        // Create a link element
        const link = document.createElement('a');
    
        // Set the href attribute to the image URL
        link.href = imageUrl;
    
        // Set the download attribute to prompt the user to download the image
        link.download = 'image';
    
        // Append the link to the document
        document.body.appendChild(link);
    
        // Trigger a click event on the link to start the download
        link.click();
    
        // Remove the link from the document
        document.body.removeChild(link);
    }

 
      
    
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const handlePreview = async (content) => {
      
    
        if (content.isPreview === true) {
            const res = await axios.get(`https://api.asgarzademusicschool.com/api/v1/CourseContent/GetById/${content.id}`);
            setPreviewVideo(res.data.data.videoLink);
            const sortedFiles = res.data.data.courseFileGetDtos.sort((a, b) => {
                const getFileExtension = (filePath) => filePath.split('.').pop().split('?')[0].toLowerCase();
                const aExtension = getFileExtension(a.filePath);
                const bExtension = getFileExtension(b.filePath);
        
                // Word dosyalarını en başa alın
                if ((aExtension === 'doc' || aExtension === 'docx') && !(bExtension === 'doc' || bExtension === 'docx')) {
                    return -1;
                } else if (!(aExtension === 'doc' || aExtension === 'docx') && (bExtension === 'doc' || bExtension === 'docx')) {
                    return 1;
                }
        
                return 0;
            });
        
            setFiles(sortedFiles);
            console.log(sortedFiles);
            setOpen(true);
        }
    
     
    };
    
    



    return (
    
      
       <>
       <Sheet  disableScrollLocking={true}  isOpen={isOpen} onClose={() => setOpen(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content style={{backgroundColor:"#121212",overflow:"scroll"}}> 
          
          <div>

            <div className='videoprev' style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>

            <video autoPlay controls style={{width:"80%",height:'90%'}} controlsList="nodownload" src={previewVideo}>


</video>
            </div>
           <div style={{width:"100%"}}>
           <div style={{padding:"20px 10%"}} className='notes'>
    {
     files && files.map((file, index) => {
        const fileExtension = file.filePath.split('.').pop().toLowerCase();
        
        switch (fileExtension.split('?')[0]) {
            case 'pdf':
                return (
                  <>
<div >
<a 

href={file.filePath} 
target="_blank" 
rel="noopener noreferrer" 
key={index}
style={{
    padding: '5px 7px',
    color: 'rgb(238, 155, 7)',
    border: '1px solid rgb(238, 155, 7)',

    marginBottom:"35px",
    // maxWidth:'350px',
    // maxWidth:'350px',
    textAlign: 'center',
    cursor:"pointer",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
    
}}
download={file.fileName}
>
<IconDow />
{file.fileName}
</a>
</div>
     <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div style={{ height: "720px", width:"100%"}}>

       <Viewer  
          download={false}
         plugins={[defaultLayoutPluginInstance]} fileUrl={file.filePath} />;
</div>
     </Worker>
                  </>
                 
                    
                );
            case 'doc':
            case 'docx':
            
                return (

                  <>
                  <a 

                  href={file.filePath} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  key={index}
                  style={{
                      padding: '5px 7px',
                      color: 'rgb(238, 155, 7)',
                      border: '1px solid rgb(238, 155, 7)',

                      marginBottom:"35px",
                      // maxWidth:'350px',
                      // maxWidth:'350px',
                      textAlign: 'center',
                      cursor:"pointer",
                      display:"flex",
                      justifyContent:"space-between",
                      alignItems:"center"
                  }}
                  download={file.fileName}
              >
            <IconDow />
                  {file.fileName}
              </a>
              <br></br>
                  </>
         
              
                );
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                // Resim dosyaları için bir img tag
                return (
                  <div>
                      <img
                          src={file.filePath}
                          alt={`Image ${index}`}
                          style={{ width: '100%', height: '600px', border: 'none' }}
                          onClick={() => downloadImage(file.filePath)}
                      />
                  </div>
              );
            default:
                // Diğer dosya türleri için genel bir çözüm
                return (
                    <a href={file.filePath} target="_blank" rel="noopener noreferrer" key={index} style={{
                      padding: '5px 7px',
                      color: 'rgb(238, 155, 7)',
                      border: '1px solid rgb(238, 155, 7)',
                      maxWidth:'350px',
                      textAlign: 'center',
                      cursor:"pointer",
                      display:"flex",
                      justifyContent:"space-between",
                      alignItems:"center"
                  }}>
                       {file.fileName}
                    </a>
                );
        }
    })
    
    }
</div>
           </div>
   
          </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
        
        <section className='course-content-course'>
            <div className="container">
                <div className="course-content">
                    <div className='course-content-head'>
                        <h5>Course content</h5>
                        <span>{chapterCount} chapters • {videoCount} lessons </span>
                    </div>
                    <ul className='course-content-lists'>
                    {chapterGetDtos?.map((chapter, index) => (
<li key={index} className='list' >
<div className='list-first' onClick={() => handleItemClick(index)}>
 <div className='list-first-left-part'>
     <img src={Vector} alt="vector" className={activeChapter === index ? 'rotate' : ''} />
     <span>{chapter.title}</span>
 </div>
 <div className='list-first-right-part'>
     <span >{chapter.courseContents.length} lessons</span>
     <Circle />
     <span> {chapter.courseContents.length*10}+ minutes</span>
 </div>
</div>
{activeChapter === index && (
 <div className='list-second' >
     {chapter?.courseContents.map((content,key)=>(
        <div
  style={{cursor: content.isPreview ? "pointer" : "default"}}
  key={key}
  className='list-second-one'
  onClick={content.isPreview ? e => handlePreview(content) : undefined}
>
         <div className='list-second-one-left' >
             <div style={{maxWidth:"30px",minWidth:"30px"}}><FilmIcon style={{width:'100%'}} /></div>
             <h6 style={{fontSize:"14px"}}>{content.title}</h6>
         
         </div>
         <div className='list-second-one-right'>
             <h6><span className='special-header-text'>{content.isPreview && 'Preview'}</span></h6>
         </div>
     </div>
     ))}
 </div>
)}

</li>
                    ))}
                    </ul>
                </div>
            </div>
        </section>
       </>
    )
}

export default CourseContent
